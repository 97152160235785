<template>
    <div class="plan-detail">
    <div class="container">
      <!-- <div class="row">
        <div class="col-sm-6 width-plan-detail"> -->
          <img v-if="product._embedded['wp:featuredmedia']" v-lazy="{src: product._embedded['wp:featuredmedia'][0].source_url}" class="img-wp-thumbnail" alt="">
          <p class="title2 title-border title-product">{{ product.title.rendered }}</p>
          <div v-html="product.excerpt.rendered" class="excerpt"></div>
        <!-- </div>
        <div class="col-sm-6 width-plan-detail"> -->
          

          
        <!-- </div> -->
      <!-- </div> -->
    </div>
    <div class="product-detail">
      <div class="container">
        <div class="tabPanel-widget">
            <label for="tab-1" tabindex="0"></label>
            <input id="tab-1" type="radio" name="tabs" checked="true" aria-hidden="true">
            <p>{{ $t('product.detail') }}</p>
            <!-- <div class="panel-wrapper">
                <a href="#show" class="show btn12" id="show">โชว์</a> 
                <a href="#hide" class="hide btn12" id="hide">ซ่อน</a> 
                <div class="panel">
                    <div class="gallery-item">
                       <div class="detail-product" v-html="product.content.rendered"></div>
                    </div>
                </div>
                <div class="fade"></div>
            </div> -->
            <div class="detail-product" v-html="product.content.rendered"></div>
        </div>
      </div>
    </div>
    <div class="register">
      <div class="container">
      <p class="title2 title-border">{{ $t('product.register') }}</p>
          
          <div class="default-form">
              <form @submit.prevent="sendEmail">
                  <div class="row">
                      <div class="col-md-6 col-sm-6 col-xs-12">
                          <input type="hidden" name="productName" v-model="product.title.rendered">
                          <input type="hidden" name="productID" v-model="productID">
                          <div class="form-group">
                              <input type="text" name="name" v-model="name" :placeholder="$t('product.nameForm')" required>
                          </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                              <input type="text" name="lastname" v-model="lastname" :placeholder="$t('product.lastnameForm')" required>
                          </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                              <input type="email" name="email" v-model="email" placeholder="E-mail" required>
                          </div>           
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                              <input type="text" name="phone" v-model="phone" :placeholder="$t('product.tel')" required>
                          </div>           
                      </div>
                      <div class="col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                              <input type="text" name="address" v-model="address" :placeholder="$t('product.addressForm')" required>
                          </div>           
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                              <input type="text" name="landwide" v-model="landwide" :placeholder="$t('product.landWide')" required>
                          </div>           
                      </div>
                      <div class="col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                              <input type="text" name="landheight" v-model="landheight" :placeholder="$t('product.landHeight')" required>
                          </div>           
                      </div>
                      <div class="col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <label for="direction">{{ $t('product.directionLabel') }}</label>
                              <select class="custom-select-box" v-model="direction" name="direction">
                                  <option disabled value="">{{ $t('product.direction') }}</option>
                                  <option>{{ $t('product.north') }}</option>
                                  <option>{{ $t('product.south') }}</option>
                                  <option>{{ $t('product.east') }}</option>
                                  <option>{{ $t('product.west') }}</option>
                              </select>
                          </div>          
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                          <button class="theme-btn appointment-btn" type="submit" name="submit-form">{{ $t('product.buy') }}</button>
                      </div>      
                  </div>
              </form>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from 'emailjs-com'
export default {
  computed: {
    wideHouse() {
      return this.$store.state.wideHouse;
    },
    product() {
      return this.wideHouse.find(el => el.slug === this.slug);
    }
  },
  data() {
    return {
      slug: this.$route.params.slug,
      name: '',
      lastname: '',
      email: '',
      phone: '',
      address: '',
      landwide: '',
      landheight: '',
      direction: '',
      productName: '',
      productID: this.$route.params.slug
    };
  },
  created() {
    this.$store.dispatch("getWideHouse");
  },
  methods: {
    sendEmail(e) {
      try{
        emailjs.sendForm('service_lp9ei4m', 'template_wkfljw9', e.target, 'user_whqAVDxlOR8N4y9MOkwQh', {
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          address: this.address,
          landwide: this.landwide,
          landheight: this.landheight,
          direction: this.direction,
          productName: this.productName,
          productID: this.productID
        })
        console.log('success')

      } catch(error) {
        console.log({error})
      }
      this.name='',
      this.lastname='',
      this.email='',
      this.phone='',
      this.address='',
      this.landwide='',
      this.landheight='',
      this.direction=''
      this.productName='',
      this.productID=''
    }
  }
};
</script>